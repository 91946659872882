<template>
  <h5>Report</h5>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-fw pi-thumbs-up text-blue-500 text-xl"></i>
          </div>
        </div>
        <div>
          <span class="block text-500 font-medium mb-3"
            ><Button
              style="width: 100%"
              label="Lecture View Count"
              class="p-button-outlined p-button-success"
              @click="lec_view"
          /></span>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-fw pi-question-circle text-blue-500 text-xl"></i>
          </div>
        </div>
        <div>
          <span class="block text-500 font-medium mb-3"
            ><Button
              style="width: 100%"
              label="Teacher's Assigment and Student Submission"
              class="p-button-outlined p-button-success"
              @click="teacher_view"
          /></span>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div
            class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            "
            style="width: 2.5rem; height: 2.5rem; align: center"
          >
            <i class="pi pi-fw pi-arrow-circle-down text-blue-500 text-xl"></i>
          </div>
        </div>
        <div>
          <span class="block text-500 font-medium mb-3"
            ><Button
              style="width: 100%"
              label="Download Student Registration"
              class="p-button-outlined p-button-success"
              @click="download_std_reg"
          /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  async created() {},
  async mounted() {},
  methods: {
    download_std_reg() {
      this.$router.push("/studentregistrationreport");
    },
    teacher_view() {
      this.$router.push("/adminteacherlecreport");
    },
    lec_view() {
      this.$router.push("/lectureviewreport");
    },
  },
};
</script>

<style>
</style>